<template>
  <div class="main gray-bg">
    <!--顶部图标-->
    <div class="flex justify-end align-center top">
      <router-link class="top-icon" to="/tool/notice-list">
        <van-icon v-if="userInfo.has_notice" :name="require('@/assets/images/personal/chat-o.png')" dot/>
        <van-icon v-else :name="require('@/assets/images/personal/chat-o.png')"/>
      </router-link>
      <router-link class="top-icon" to="/tool/help-center">
        <van-icon :name="require('@/assets/images/personal/service-o.png')"/>
      </router-link>
    </div>
    <!--头部信息-->
    <div class="flex align-center header">
      <div class="user-icon">
        <van-image round :src="userInfo.head_img ? userInfo.head_img : require('@/assets/images/personal/user.png')"/>
      </div>
      <div class="user-info">
        <div class="van-ellipsis user-account"><span class="label"><i>账</i><i>号</i></span>：{{ userInfo.account }} <van-tag @click="asyncCopy(userInfo.account)" plain type="primary" size="small">复制</van-tag></div>
        <div class="van-ellipsis user-phone"><span class="label"><i>手</i><i>机</i><i>号</i></span>：{{ userInfo.phone ? userInfo.phone : '未绑定' }}</div>
      </div>
    </div>
    <!--中间菜单-->
    <div class="flex justify-between align-center nav">
      <router-link class="nav-cell" to="/personal/wallet">
        <van-icon :name="require('@/assets/images/personal/cash-o.png')"/>
        <div class="nav-text">钱包</div>
      </router-link>
      <router-link class="nav-cell" to="/personal/gift">
        <van-icon :name="require('@/assets/images/personal/gift-o.png')"/>
        <div class="nav-text">礼包</div>
      </router-link>
      <router-link class="nav-cell" to="/personal/voucher-card">
        <van-icon :name="require('@/assets/images/personal/card-o.png')"/>
        <div class="nav-text">卡券</div>
      </router-link>
      <router-link class="nav-cell" to="/personal/activity">
        <van-icon :name="require('@/assets/images/personal/active-o.png')"/>
        <div class="nav-text">活动</div>
      </router-link>
      <router-link class="nav-cell" to="/personal/server-list">
        <van-icon :name="require('@/assets/images/personal/list-o.png')"/>
        <div class="nav-text">开服表</div>
      </router-link>
    </div>
    <!--列表菜单-->
    <van-cell-group class="cell-list">
      <van-cell title="订单记录" :icon="require('@/assets/images/personal/cell-order.png')" is-link to="/personal/spendList"/>
      <van-cell title="修改密码" :icon="require('@/assets/images/personal/cell-pass.png')" is-link to="/personal/password"/>
      <van-cell title="小号管理" :icon="require('@/assets/images/personal/cell-sub.png')" is-link to="/personal/subList"/>
      <van-cell class="cell-tag" title="绑定手机" :class="userInfo.phone ? '' : 'hot-tag'"
                :value="userInfo.phone ? '已绑定' : '去绑定'" :icon="require('@/assets/images/personal/cell-phone.png')" is-link
                :to="userInfo.phone ? '/personal/bound-phone' : '/personal/bind-phone'"/>
      <van-cell class="cell-tag" title="防沉迷系统" :class="userInfo.real_name && userInfo.id_card ? '' : 'hot-tag'"
                :value="userInfo.real_name && userInfo.id_card ? '已认证' : '去认证'"
                :icon="require('@/assets/images/personal/cell-anti.png')" is-link to="/personal/auth"/>
      <van-cell title="隐藏浮球" :icon="require('@/assets/images/personal/cell-float.png')" is-link
                @click="floatDialog = true"/>
      <van-cell title="自动登陆" :icon="require('@/assets/images/personal/cell-login.png')">
        <van-switch v-model="checked" @change="switchAutoLogin"/>
      </van-cell>
    </van-cell-group>

    <!--版本号-->
<!--    <div class="show-tips">V2.1.0</div>-->
    <!--底部按钮-->
    <div class="bottom-bar">
      <van-button type="primary" round @click="closeCurrent">返回游戏</van-button>
    </div>
    <!--悬浮球操作窗-->
    <van-dialog :show="floatDialog" confirm-button-text="暂不隐藏" cancel-button-text="继续隐藏"
                show-cancel-button @confirm="keepShowFloat" @cancel="keepCloseFloat">
      <div class="dialog-head">重启游戏即可重新显示悬浮球</div>
    </van-dialog>
  </div>
</template>

<script>
import {Icon, Cell, CellGroup, Button, Switch, Image as VanImage, Dialog, Toast, Tag} from 'vant';
import {ref, computed} from 'vue';
import {returnGameCallback} from "@/utils/callback";
import "@/style/common.css"
import {setAutoLogin, setFloat} from "@/utils/callback";
import {useStore} from "vuex";
import {asyncCopy} from "@/utils/function";

export default {
  name: "Person",
  components: {
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Switch.name]: Switch,
    [VanImage.name]: VanImage,
    [Tag.name]:Tag,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const store = useStore();
    //初始化用户信息
    const userInfo = computed(() => {return store.state.userInfo})
    if (Object.keys(userInfo.value).length === 0){
      Toast.loading({
        message: "加载中...",
        overlay: true,
        duration: 500,
        forbidClick: true,
      });
      store.commit('updateUserInfo')
    }
    const checked = ref(typeof store.state.isAutoLogin == 'string' ? (store.state.isAutoLogin == 'true' ? true : false) : store.state.isAutoLogin);
    //设置悬浮球弹窗
    const floatDialog = ref(false)
    // 继续隐藏
    const keepCloseFloat = () => {
      floatDialog.value = false
      setFloat()
    }
    // 暂不隐藏
    const keepShowFloat = () => {
      floatDialog.value = false
    }

    const closeCurrent = () => {
      returnGameCallback()
    }

    const switchAutoLogin = (value) => {
      checked.value = value
      setAutoLogin(value)
    }

    return {
      checked,
      userInfo,
      floatDialog,
      asyncCopy,
      closeCurrent,
      keepShowFloat,
      keepCloseFloat,
      switchAutoLogin,
    };
  },
}
</script>

<style scoped>
.main {
  background: url("../../../assets/images/personal/main-bg.png") top no-repeat;
  background-size: 100% auto;
  padding: 10px 15px;
  padding-bottom: 80px;
}

.top {
  padding: 10px 0px;
}

.top-icon {
  margin-left: 15px;
  width: 22px;
  height: 22px;
  position: relative;
  font-size: 22px;
  cursor: pointer;
}

.top-icon :deep(.van-badge--dot) {
  width: 5px;
  height: 5px;
  background: #FF0F1D;
}

.header {
  height: 54px;
  overflow: hidden;
}

.user-icon {
  width: 53px;
  height: 53px;
  margin-right: 15px;
}

.user-info {
  text-align: left;
}
.user-info .label{
  display:inline-flex;
  width:40px;
  justify-content: space-between;
}
.user-info .label i{
  font-style: normal;
}
.user-account {
  font-weight: 500;
  color: #1D2129;
  line-height:24px;
  height: 24px;
  font-size:16px;
}

.user-phone {
  height:20px;
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
}

.nav {
  background: linear-gradient(180deg, #FAFCFF 0%, #FBFCFF 100%);
  box-shadow: 0px 2px 7px 0px #E0E8FE;
  border-radius: 6px;
  padding: 12px 20px;
  font-size: 32px;
  margin: 12px auto;
}

.nav-text {
  font-size: 12px;
  font-weight: 400;
  color: #4E5969;
  line-height: 17px;
  height: 17px;
  text-align: center;
}

.cell-list {
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px #EFF3FF;
  border-radius: 6px;
  overflow: hidden;
}

.cell-list .van-cell::after {
  border-bottom: 2px solid #DCDCDC;
}

.cell-list :deep(.van-cell__left-icon) {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.cell-list :deep(.van-cell__title) {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
}

.cell-list :deep(.van-cell__value) {
  font-size: 14px;
  font-weight: 400;
  color: #A5ADB7;
}


.hot-tag :deep(.van-cell__value) {
  color: #F93E49;
  opacity: 1;
}
.cell-list :deep(.van-cell__right-icon) {
  font-size: 10px;
  color: #86909C;
}

.cell-list .van-switch {
  width: 30px;
  height: 18px;
}

.cell-list :deep(.van-switch__node) {
  width: 18px;
  height: 18px;
}

.cell-list .van-switch--on :deep(.van-switch__node) {
  transform: translate(12px);
}

.show-tips {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #A5ADB7;
  line-height: 20px;
  margin-top: 14px;
}

.bottom-bar .van-button {
  border-radius: 25px;
  width: 100%;
  height: 50px;
  font-size: 17px;
}

.dialog-head {
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #1D2129;
  line-height: 25px;
  margin-top: 16px;
}
</style>
